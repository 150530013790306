import useragent from "express-useragent";
const isWebview = require("is-ua-webview");
import { IUserAgent } from "../types/ua";
import { parseQuery } from "./utils";
export const getUserAgent = (): IUserAgent => {
  if (typeof window !== "undefined") {
    const source = navigator.userAgent;
    const ua = useragent.parse(source);
    return {
      isBot: ua.isBot,
      isMobile: ua.isMobile,
      fromApp: parseQuery("from") === "app" ? true : false,
      isWebView: isWebview(source),
      // is: ua.We
      // isTablet: ua.isTablet,
      // isiPad: ua.isiPad,
      // isiPod: ua.isiPod,
      isiPhone: ua.isiPhone,
      isAndroid: ua.isAndroid,
      // isOpera: ua.isOpera,
      hasWebp:
        !ua.isiPhone &&
        !ua.isiPad &&
        !ua.isTablet &&
        (ua.isOpera || ua.isChrome),
      isSafari: ua.isSafari,
      isChrome: ua.isChrome,
    } as IUserAgent;
  }
  return {} as IUserAgent;
};
