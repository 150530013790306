import { isWeekend, addDays } from "./date";

export const capitalizeFirstLetter = (name: string | null) => {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  return "";
};
export const parseQuery = (variable) => {
  if (typeof window !== "undefined") {
    const query = window.location.search.substring(1);
    if (query) {
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }
  }
  return "";
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const checkWeekend = (
  day: Date
): { type?: "weekend" | "morning" | "night"; val: boolean } => {
  const hour = day.getHours();
  const time = hour * 60 + day.getMinutes();
  const isOnline = time >= 570 && time <= 1110;
  if (isOnline && !isWeekend(new Date())) {
    return { val: true };
  } else {
    if (isWeekend(new Date())) {
      return { type: "weekend", val: false };
    } else if (hour >= 0 && hour <= 9) {
      return { type: "morning", val: false };
    } else if (isWeekend(addDays(new Date(), 1))) {
      return { type: "weekend", val: false };
    } else {
      return { type: "night", val: false };
    }
  }
};

export const transformProfile = (gender?: number) => {
  return gender == null
    ? "undefined"
    : gender === 2
    ? "unisex"
    : gender === 1
    ? "men"
    : "women";
};

export const offset = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export const roundThousand = (i: number) => {
  if (i) {
    return Math.floor(i / 1000) * 1000;
  }
  return i;
};
