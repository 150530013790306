const path = require("path");

module.exports = {
  i18n: {
    // // These are all the locales you want to support in
    // // your application
    locales: ["en", "fr"],
    // // This is the default locale you want to be used when visiting
    // // a non-locale prefixed path e.g. `/hello`
    defaultLocale: "en",
    localeDetection: true,
    // TODO j'ai mis nextI18NextConfig car sinon on a un soucis avec ?from=app (ou toutes les queries), ca infinite refresh
    serializeConfig: false,
    localePath: path.resolve("./public/locales"),
    // otherLanguages: ['en'],
    // localePath: path.resolve('./src/public/static/locales')
  },
};
