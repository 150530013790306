import * as React from "react";
import nextI18NextConfig from "../next-i18next.config.js";
import App from "next/app";
import Head from "next/head";
import { bugsnagClient } from "../api/bugsnag";

import "../styles/styles.scss";
import "intersection-observer";

const Header = React.memo(function Header() {
  return (
    <Head>
      <meta charSet="utf-8" />
      {process.env.TYPE === "development" ? (
        <meta name="robots" content="noindex" />
      ) : null}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1,  maximum-scale=1, user-scalable=no, shrink-to-fit=no"
      />
      <meta
        key="Content-Security-Policy"
        httpEquiv="Content-Security-Policy"
        content={CSP}
      />
      <meta
        name="google-site-verification"
        content="dWRAzIxvULceQhbENcU7lcnekZSfw5XWMGYBn1XuTCQ"
      />
      {/* <script dangerouslySetInnerHTML={{ __html: `!function(n,e){var t,o,i,c=[],f={passive:!0,capture:!0},r=new Date,a="pointerup",u="pointercancel";function p(n,c){t||(t=c,o=n,i=new Date,w(e),s())}function s(){o>=0&&o<i-r&&(c.forEach(function(n){n(o,t)}),c=[])}function l(t){if(t.cancelable){var o=(t.timeStamp>1e12?new Date:performance.now())-t.timeStamp;"pointerdown"==t.type?function(t,o){function i(){p(t,o),r()}function c(){r()}function r(){e(a,i,f),e(u,c,f)}n(a,i,f),n(u,c,f)}(o,t):p(o,t)}}function w(n){["click","mousedown","keydown","touchstart","pointerdown"].forEach(function(e){n(e,l,f)})}w(n),self.perfMetrics=self.perfMetrics||{},self.perfMetrics.onFirstInputDelay=function(n){c.push(n),s()}}(addEventListener,removeEventListener);` }} /> */}
    </Head>
  );
});

import { trackEventSegment } from "../api";
import { CSP } from "../config";
import MetaHeader from "../components/meta-header/meta-header";
import dynamic from "next/dynamic";
import ModalAppComponent from "../components/modal_app/modal_app-component";
const CookiesComponent = dynamic(
  () => import("../components/cookies/cookies-component")
);
import { getUserAgent } from "utils/agent";
import { appWithTranslation } from "next-i18next";
import { getAppleMeta, openURL, openStore } from "../utils/redirect";
import { IUserAgent } from "types/ua.js";
class MyApp extends App {
  public state = {
    hasModal: false,
    userAgent: {} as IUserAgent,
  };

  public getHasModal(userAgent: IUserAgent) {
    let asPath = this.props.router.asPath;
    if (asPath.includes("?")) {
      asPath = asPath.split("?")[0];
    }
    return (
      userAgent &&
      userAgent.isMobile &&
      !userAgent.fromApp &&
      (asPath.match(/(sales|cart|sale|product|prd|tester)/) ||
        this.props.router.pathname === "/home" ||
        asPath.match("cart") ||
        asPath.match("referral") ||
        asPath.match("chat") ||
        asPath.match("order") ||
        asPath.match("tester") ||
        asPath.match("orders"))
      // ||
      // asPath.match("invite")
    );
  }

  public async componentDidMount() {
    window.console.log(
      "%cChoose recrute des gens comme toi!😎\n\n%chttps://www.welcometothejungle.com/fr/companies/choose\n\n",
      "color: #0794dc;font-size: 24px;font-weight:bold",
      "color: #9aa7b4;font-size: 18px"
    );
    const userAgent = getUserAgent();
    this.setState(
      {
        hasModal: this.getHasModal(userAgent),
        userAgent,
      },
      () => {
        if (
          this.state.hasModal &&
          this.props.router.query.openstore &&
          this.props.router.pathname !== "/tester"
        ) {
          openStore(userAgent, this.props.router.pathname);
        }
      }
    );
  }

  public componentDidCatch(error, errorInfo) {
    if (process.env.TYPE === "development") {
      console.log("CUSTOM ERROR HANDLING", error);
    }
    bugsnagClient.notify(error, (report) => {
      report.addMetadata("infos", errorInfo);
    });
    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo);
  }

  public onOpenApp = (doNotTrack?: boolean) => {
    if (doNotTrack) {
      trackEventSegment("onOpenApp", {
        location: window.location.href,
      });
    }

    this.openApp(getAppleMeta(this.props.router, this.props.pageProps));
  };

  public openApp = (inAppUrl: string) => {
    openURL(this.state.userAgent, this.props.router.pathname, inAppUrl, false);
  };

  public onHideApp = () => {
    trackEventSegment("onHideApp");
  };

  public render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <MetaHeader />
        <Header />
        <Component {...pageProps} />
        {this.state.hasModal ? (
          <ModalAppComponent
            className={this.state.userAgent.isWebView ? "webview" : "normal"}
            onHideApp={this.onHideApp}
            onOpenApp={this.onOpenApp}
          />
        ) : null}
        {!this.state.hasModal &&
        !(
          this.props.router.pathname &&
          (this.props.router.pathname === "/confirm_alma" ||
            this.props.router.pathname === "/payment" ||
            (this.props.router.query && this.props.router.query.from === "app"))
        ) ? (
          <CookiesComponent />
        ) : null}
      </>
    );
  }
}

// TODO j'ai mis nextI18NextConfig car sinon on a un soucis avec ?from=app (ou toutes les queries), ca infinite refresh
export default appWithTranslation(MyApp, nextI18NextConfig as any);
