import * as React from "react";
import styles from "./modal_app-component.module.scss";
import { withTranslation } from "next-i18next";
// tslint:disable-next-line:no-empty-interface
interface IModalAppComponent {
  onOpenApp: () => void;
  onHideApp: () => void;
  t?: any;
  className: "webview" | "normal";
}

class ModalAppComponent extends React.Component<IModalAppComponent, {}> {
  public state = {
    display: false,
  };

  public componentDidMount() {
    try {
      if (
        typeof window !== "undefined" &&
        typeof window.sessionStorage !== "undefined" &&
        !window.sessionStorage.getItem("hide_app")
      ) {
        this.setState({
          display: true,
        });
      }
    } catch (e) {
      //
    }
  }

  public onOpen = () => {
    this.props.onOpenApp();
  };

  public hide = () => {
    this.setState(
      {
        display: false,
      },
      () => {
        window.sessionStorage.setItem("hide_app", "true");
      }
    );
  };

  get getClass() {
    return styles.modal + " fadeIn animated " + this.props.className;
  }

  public onHide = () => {
    this.props.onHideApp();
    this.hide();
  };
  public render() {
    return this.state.display ? (
      <div className={this.getClass}>
        <img
          alt="logo"
          height="64 "
          width="64"
          className={styles.logo}
          src="https://public.choose.app/website/new/images/logo-app.png"
          srcSet={`https://public.choose.app/website/new/images/logo-app@2x.png 2x,https://public.choose.app/website/new/images/logo-app@3x.png 3x`}
        />
        <h4>{this.props.t("modal_title")}</h4>
        <p>{this.props.t("modal_text")}</p>
        <img
          className={styles.review}
          alt="review"
          height="16 "
          width="109"
          src="https://public.choose.app/website/new/images/review.png"
          srcSet={`https://public.choose.app/website/new/images/review@2x.png 2x,https://public.choose.app/website/new/images/review@3x.png 3x`}
        />
        <button onClick={this.onOpen}>{this.props.t("modal_go")}</button>
        <button onClick={this.onHide}>{this.props.t("modal_not")}</button>
      </div>
    ) : null;
  }
}

export default withTranslation("common")(ModalAppComponent as any) as any;
