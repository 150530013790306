export const trackPage = (page?: string, payload?: any) => {
  if ((window as any).amplitude) {
    (window as any).amplitude.track(
      "View" + page,
      payload
        ? { ...payload, url: document.location.pathname }
        : {
            url: document.location.pathname,
          }
    );
  }
};

export const trackEventSegment = (
  event_name: string,
  event_properties?: any
) => {
  if ((window as any).amplitude) {
    (window as any).amplitude.track(event_name, event_properties);
  }
};
