import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { version } from "../config";

export const bugsnagClient = Bugsnag.start({
  appVersion: version,
  releaseStage: process.env.releaseStage,
  apiKey: "a5f37bbce8e69644aa09bd36e50d3ea9",
  logger: null,
  plugins: [new BugsnagPluginReact(React)],
});

export const ErrorBoundary = Bugsnag.getPlugin("react");
